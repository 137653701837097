import ApiService from "@/core/services/api.service";

export const SET_LISTA_PASTAS = "setListPastas";
export const SET_LISTA_PASTAS_DOC = "setListPastasDoc";
export const SET_LISTA_PASTAS_DOC_REV = "setListPastasDocRev";
export const SET_LISTA_PASTAS_REV = "setListPastasRev";
export const SET_LISTA_PASTAS_NEW = "setNewFolder"
export const SET_LISTA_PASTAS_AT = "setAtFolder"
export const SET_LISTA_DOCUMENTO_NEW = "setListDocumentoNew"
export const SET_PASTA = "SetPasta"
export const SET_MESSAGE_ALERT = 'setMensagem'

const actions = {
  async create_pastas(context, value) {



    let message = await ApiService.post('pastas', value)
      .then(response => ({ tipo: 'success', message: response.data }))
      .catch((error) => ({ tipo: 'error', message: error.response.data }))
    context.commit(SET_MESSAGE_ALERT, message)
    context.commit(SET_LISTA_PASTAS_NEW, message.message.conteudo)


  },
  async update_pastas(context, value) {


    let message = await ApiService.put("pastas/" + value.id, value)
      .then(response => ({ tipo: 'success', message: response.data }))
      .catch((error) => ({ tipo: 'error', message: error.response.data }))
    context.commit(SET_MESSAGE_ALERT, message)
    context.commit(SET_LISTA_PASTAS_AT, message.message.conteudo)

  },
  async delete_pastas(context, value) {


    await ApiService.delete("pastas/" + value.id)
      .then((response) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "error",
          message: error.response.data,
        })
      );


    context.commit(SET_LISTA_PASTAS_REV, value)
    context.commit(SET_LISTA_PASTAS_DOC_REV, value)


  },
  async delete_documento(context, value) {


    await ApiService.delete("documento/" + value.id)
      .then((response) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "success",
          message: response.data,
        })
      )
      .catch((error) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "error",
          message: error.response.data,
        })
      );



    // context.commit(SET_LISTA_PASTAS_REV, value)
    context.commit(SET_LISTA_PASTAS_DOC_REV, value)


  },



  async listar_pastas(context, value) {

    await ApiService.get("pastas")
      .then((response) => context.commit(SET_LISTA_PASTAS, response.data))
      .catch((error) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "error",
          message: error.response.data,
        })
      );
  },

  async listar_pasta(context, value) {

    await ApiService.get("pastas/" + value)
      .then((response) => context.commit(SET_PASTA, response.data))
      .catch((error) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  async set_pasta(context, value) {
    context.commit(SET_PASTA, value);
  },
  async listar_pastas_doc(context, value) {

    await ApiService.get("pastas/doc/" + value)
      .then((response) => context.commit(SET_LISTA_PASTAS_DOC, response.data))

      .catch((error) =>
        context.commit(SET_MESSAGE_ALERT, {
          tipo: "error",
          message: error.response.data,
        })
      );
  },
  set_lista_pastas(context, value) {
    context.commit(SET_LISTA_PASTAS, value)
    context.commit(SET_LISTA_PASTAS_NEW, {})
    context.commit(SET_LISTA_PASTAS_AT, {})

  },
  async create_documento(context, value) {



    let message = await ApiService.post('documento', value)
      .then(response => ({ tipo: 'success', message: response.data }))
      .catch((error) => ({ tipo: 'error', message: error.response.data }))
    context.commit(SET_MESSAGE_ALERT, message)
    context.commit(SET_LISTA_DOCUMENTO_NEW, message.message.conteudo)
  },




};

const getters = {};

const mutations = {
  [SET_LISTA_PASTAS](state, value) {
    state.lista_pastas = value;
  },
  [SET_PASTA](state, value) {
    state.pasta = value;
  },
  [SET_LISTA_PASTAS_NEW](state, value) {
    state.new_pasta = value;
  },
  [SET_LISTA_DOCUMENTO_NEW](state, value) {
    state.lista_pastas_doc.push(value)

  },

  [SET_LISTA_PASTAS_AT](state, value) {
    state.at_pasta = value;
  },
  [SET_LISTA_PASTAS_REV](state, value) {
    state.lista_pastas = state.lista_pastas.filter(
      (pastas) => pastas.id !== value.id
    );
  },
  [SET_LISTA_PASTAS_DOC](state, value) {
    state.lista_pastas_doc = value

  },
  [SET_LISTA_PASTAS_DOC_REV](state, value) {
    state.lista_pastas_doc = state.lista_pastas_doc.filter(
      (data) => data.id !== value.id
    );
  },

  [SET_MESSAGE_ALERT](state, value) {
    console.log(value)
    let customMessage;
    if (typeof value.message === "object") {

      customMessage = value.message.msg;
    }
    state.mensagem_alert = {
      tipo: value.tipo,
      message: customMessage ? customMessage : value.message,
    };
  },


};

const state = {
  lista_pastas: [],
  lista_pastas_doc: [],
  new_pasta: {},
  at_pasta: {},
  mensagem_alert: "",
  pasta: {}

};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
